/**
 * This script adds an event listener to the document that waits for the DOM content to be fully loaded.
 * Once the DOM is loaded, it queries for all elements with the class 'action-button' and adds a click event listener to each of them.
 * When an 'action-button' is clicked, it prepares and executes a reCAPTCHA request.
 * If the reCAPTCHA request is successful, it appends the reCAPTCHA token to the button's href as a query parameter and opens the URL in a new window or tab.
 * The event's default action is prevented to stop the browser from following the link.
 */
document.addEventListener("DOMContentLoaded", function () {
    // Query all elements with the class "action-button"
    const buttons = document.querySelectorAll(".action-button");
    // For each button, add a click event listener
    buttons.forEach(function (button) {
        button.addEventListener("click", function (e) {
            console.log("button clicked");
            const target = button.getAttribute("target");
            // iOS Safari does not allow opening a new window/tab without user interaction,
            // so we need to open the window here before any async function
            const myWindow = window.open("", target);
            e.preventDefault();
            // Prepare and execute a reCAPTCHA request when the button is clicked
            grecaptcha.ready(function () {
                const href = button.getAttribute("href");
                grecaptcha.execute(window.dtc.recaptcha, {action: "report"})
                    .then(function (token) {
                        const url = new URL(href);
                        // Append the reCAPTCHA token to the URL as a query parameter
                        url.searchParams.append("g", token);

                        // Open the URL
                        myWindow.location = url.toString();
                    }).catch(function (error) {
                        // Handle any errors
                        window["console"].error("reCAPTCHA execution error:", error);

                        // Open the URL
                        myWindow.location = href;
                    }
                );
            });
        });
    });
});
