/**
 * This script adds an event listener to the document that waits for the DOM content to be fully loaded.
 * Once the DOM is loaded, it queries for all elements with the class 'action-button' and adds a click event listener to each of them.
 * When an 'action-button' is clicked, it prepares and executes a reCAPTCHA request.
 * If the reCAPTCHA request is successful, it appends the reCAPTCHA token to the button's href as a query parameter and opens the URL in a new window or tab.
 * The event's default action is prevented to stop the browser from following the link.
 */

function doCaptcha(action, form, formId, event) {
    event.preventDefault();

    window["console"].log("submitting recaptcha for: ", formId);
    // Prepare and execute a reCAPTCHA request when the button is clicked
    grecaptcha.ready(function () {
        window["console"].log("ready recaptcha for: ", formId);

        grecaptcha.execute(window.dtc.recaptcha, {action: "forum"})
            .then(function (token) {
                window["console"].log("execute recaptcha for: ", formId);

                let element = form.querySelector("input[name='g-recaptcha-response']");
                if (element === null) {
                    element = document.createElement("input");
                    element.type = "hidden";
                    element.name = "g-recaptcha-response";
                    form.appendChild(element);
                }

                element.value = token;

                $(form).submit();
            }).catch(function (error) {

                window["console"].error("reCAPTCHA execution error:", error);
            }
        );
    });
}

function formCaptcha() {
    const list = {
        createtopic: "create-topic-form",
        replytopic: "reply-topic-form"
    };
    Object.entries(list).forEach(function ([action, formId]) {
        const form = document.getElementById(formId);
        if (form) {
            const input = document.getElementById("g-recaptcha-response");
            if (form.recaptcha !== true ) {
                form.recaptcha = true;
                form.addEventListener("submit", doCaptcha.bind(null, action, form, formId));
            }
        }
    });
}

MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

var observer = new MutationObserver(function(mutations, observer) {
    // fired when a mutation occurs
    formCaptcha();
});

// define what element should be observed by the observer
// and what types of mutations trigger the callback
observer.observe(document, {
    subtree: true,
    attributes: false,
    childList: true
});

document.addEventListener("DOMContentLoaded", function () {
    formCaptcha();
});

