// Google Tag Manager related

const funnelNamesMap = new Map([
  // high priority
  ['creditcard', 'Credit_Cards_Comparison'],
  ['card', 'Prepaid_Credit_Card_Comparison'],
  ['onlinetrading', 'Online_Trading_Comparison'],
  ['privatebanking', 'Private_Banking_Comparison'],
  ['mediumtermnotes', 'Medium_Term_Notes_Comparison'],
  ['pillar3aaccount', 'Pillar3a_Comparison'],
  ['retirementfund', 'Retirement_Fund_Comparison'],
  ['vestedbenefitsaccount', 'Vested_Benefits_Accounts_Comparison'],
  // medium priority
  ['privateaccount', 'Private_Accounts_Comparison'],
  ['bankingpackage', 'Banking_Comparison'],
  ['companyaccount', 'Business_Accounts_Comparison'],
  // low priority
  ['health', 'Health_Insurance_Comparison'],
  ['supplemental', 'Supplemental_Health_Insurance_Comparison'],
  ['hospital', 'Hospital_Insurance_Comparison'],
  ['life', 'Life_Insurance_Comparison'],
  ['legalprotection', 'Legal_Protection_Comparison'],
  ['travel', 'Travel_Insurance_Comparison'],
  ['caution', 'Rental_Guarantee_Comparison'],
  ['animal', 'Pet_Insurance_Comparison'],
  ['savingsaccount', 'Savings_Accounts_Comparison'],
  ['hypo', 'Mortgages_Comparison'],
  ['personalloan', 'Personal_Loan_Comparison'],
  ['businessloan', 'Business_Loan_Comparison'],
  ['mobile', 'Mobile_Plan_Comparison'],
  ['wireline', 'Internet_Plan_Comparison']
]);


// Event we track when user successfully completed the funnel
const pushOfferSent = ({funnelName, productName, isExternal = true}) => {
  const trimmedProductName = productName && productName.trim();

  if (!trimmedProductName) {
    console.error(`[GTM] No productName provided. productName: ${productName}`);
  } 

  let eventCategory;
  if (funnelName) {
    const lowerCasedFunnelName = funnelName.toLowerCase();
    eventCategory = funnelNamesMap.get(lowerCasedFunnelName);

    if (!eventCategory) {
      console.error(`[GTM] No eventCategory found for funnelName: ${lowerCasedFunnelName}`);
    }
    
  } else {
    console.error(`[GTM] No funnelName provided. funnelName: ${funnelName}`);
  }

  window.dataLayer.push({
    event: 'gtmEvent',
    eventCategory: eventCategory || 'Not_Provided',
    eventAction: 'Request_Offer_Sent',
    eventLabel: trimmedProductName || 'Not_Provided',
    conversion_type: isExternal ? 'external' : 'internal'
  });
};

window.ml_gtm = window.ml_gtm || {
  pushOfferSent
};