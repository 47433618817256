
import '../styles/frontend/main.scss'
import '../styles/frontend/lazy.scss'
import './components/debounce'

// enable for recaptcha
import './components/report_click'
import './components/recaptcha_forum'
import { createApp, reactive, computed, watch } from 'vue/dist/vue.esm-bundler.js';

window.dtc = window.dtc || {};

dtc.ajaxForm = function () {
   jQuery(document).on('submit', 'form.ajax-form:not(.exceptionForm)', function (event) {
   
      event.preventDefault();
      //update ckeditor instances, is needed else <script> tags did not get sent to server after serializing.

      if (typeof(CKEDITOR) != 'undefined') {
         for (let instanceName in CKEDITOR.instances) {
            CKEDITOR.instances[instanceName].updateElement()
         }
      }

      let $this = jQuery(this);
      let dataCallback = $this.attr('data-callback');
      let dataArguments = $this.attr('data-arguments');
      let dataHideForm = $this.attr('data-hideform');
      let dataButtonName = $this.attr('data-button');
      let dataButtonData = dataButtonName ? 'button=' + dataButtonName : 'button=';
      jQuery(event.delegateTarget).hide();

      if ($this.attr('data-ajaxloader') == 1) {
         dtc.startAjaxLoader($this);
         $('.ajax-light-loader').css('position', 'fixed')
      }
      $('.dont-submit').prop('disabled', true);
      jQuery.ajax({
         type: 'post',
         url: $this.attr('action'),
         data: $this.serialize() + '&' + dataButtonData + '&ajax-request=' + $this.attr('id'),
         success: function (data) {
            let $submitBtn = $('#' + dataButtonName);
            if ($submitBtn.length == 0) {
               $submitBtn = $this.find('[type="submit"]');
               if ($submitBtn.length == 0) {
                  $submitBtn = $('.mainArea [type="submit"]')
               }
            }

            //dtc.stopAjaxLoader($this);
            if (data == 'denied') {
               //Session ist abgelaufen, muss sich neu anmelden
               window.location.reload()
            } else {
               let obj = jQuery.parseJSON(data);

               //just for registration form todo: separate to own js
               if (obj.agbForm === 1) {

                  fgs.checkout.processAgb();
                  //$submitBtn.button('reset')
                  //dtc.stopAjaxLoader($this)

               } else if (obj.registrationForm === 1) {

                  fgs.checkout.processRegistration();

                  //$submitBtn.button('reset');
                  dtc.stopAjaxLoader($this)

               } else { //default ajax form behaviour
                  $this.find('div.alert').html('').hide();
                  $('.errorSummary').fadeOut(400, function () {
                     $(this).remove()
                  });
                  if (!jQuery.isEmptyObject(obj) && !obj.redirect && !obj.reload && !obj.notify && !obj.empty && !obj.callback && !obj.flash) {

                     let displayErrors = {};
                     if (obj.error) {
                        displayErrors = obj.error
                     } else {
                        displayErrors = obj
                     }

                     let fieldName = null;
                     let $label = null;
                     let attrid = null;
                     let labelText = null;
                     let $yiiField = null;
                     let $errorSummary = $('<div class="errorSummary" style="position:fixed;top:75px;right:15px;"><span style="color: #fff" class="fa fa-times closeErrorSummary"></span></div>');

                     let hasError = false;

                     jQuery.each(displayErrors, function (index, value) {

                        let selector = '#' + index + '_em_';
                        if ($submitBtn.attr('data-uniqid')) {
                           selector = '#' + index + '-' + $submitBtn.data('uniqid') + '_em_'
                        }
                        $yiiField = jQuery(selector);

                        if ($yiiField.length > 0) {
                           $yiiField.html(value).show()
                        } else {
                           // this happens in backend

                           fieldName = index.replace('_', '[') + ']';
                           $yiiField = jQuery('.form-group [name="' + fieldName + '"]');

                           attrid = $yiiField.attr('id');

                           attrid = attrid.replace('MStructurePagedynamic_', '');
                           jQuery('#' + attrid + '_em_').html(value).show()

                        }
                        $errorSummary.append('<a href="#' + index + '"><i class="fa fa-fw fa-link"></i> ' + value + '</a>');
                        hasError = true
                     });

                     if (hasError) {
                        //$('body').append($errorSummary);
                        $errorSummary.find('.closeErrorSummary').one('click', function () {
                           $errorSummary.fadeOut(400, function () {
                              $(this).remove()
                           })
                        })
                     }
                     if (obj.cards) {
                        // console.log(obj.cards);
                        $(document).trigger('dynamicpage:errorsummary', [obj.cards])
                     }
                     //$submitBtn.button('reset')
                     dtc.stopAjaxLoader($this)
                  } else {
                     if (dataCallback) {
                        dtc.callFunctionByName(dataCallback, window, dataArguments);
                        //$submitBtn.button('reset')
                        dtc.stopAjaxLoader($this)
                     } else {
                        //removed caused login error
                        /*if (obj.dataLayerEvent) {
                            dataLayer.push(obj.dataLayerEvent);
                        }*/
                        if (obj.redirect) {
                           window.location.href = obj.redirect
                        } else if (obj.reload) {
                           window.location.reload()
                        } else if (obj.notify) {
                           dtc.notify(obj.notify, obj.type, $this.parent(), obj.fadeIn, obj.fadeOut);
                           //$submitBtn.button('reset')
                           dtc.stopAjaxLoader($this);
                           dataHideForm ? $this.hide() : null
                        } else if (obj.flash) {
                           //console.log('flash', obj.flash)
                           obj.type = obj.type ? obj.type : 'info';
                           let $flashElement = jQuery('<div id="form-flash-' + $this.attr('id') + '" class="form-flash alert alert-' + obj.type + '" />');
                           //console.log('flash element: ', $flashElement)
                           $flashElement.html(obj.flash);
                           obj.replace = obj.replace ? obj.replace : false;
                           let flashContainer = obj.el ? $(obj.el) : $this.parent();

                           if (obj.replace) {
                              flashContainer.replaceWith($flashElement)
                           } else {
                              flashContainer.prepend($flashElement)
                           }
                           obj.scrolltop = obj.scrolltop ? obj.scrolltop : false;
                           if (obj.scrolltop) {
                              $('html, body').animate({scrollTop: 0}, 'slow')
                           }
                           //$submitBtn.button('reset')
                           dtc.stopAjaxLoader($this);
                           dataHideForm ? $this.hide() : null
                        } else if (obj.empty) {
                           //$submitBtn.button('reset')
                           dtc.stopAjaxLoader($this);
                           dataHideForm ? $this.hide() : null
                        } else if (obj.dataCallback) {
                           dtc.callFunctionByName(obj.callback, window, obj.dataArguments);
                           //$submitBtn.button('reset')
                           dtc.stopAjaxLoader($this);
                           dataHideForm ? $this.hide() : null
                        } else if (obj.nothing) {
                           //$submitBtn.button('reset')
                        } else {
                           window.location.href = window.location.href
                        }

                     }
                  }
               }
            }
         }
      });
      $('.dont-submit').prop('disabled', false);

      return false
   })
};

jQuery(document).on('submit', 'form.ajax-submit', function (event) {
    event.preventDefault();
    //update ckeditor instances, is needed else <script> tags did not get sent to server after serializing.

    if (typeof (CKEDITOR) != 'undefined') {
        for (let instanceName in CKEDITOR.instances) {
            CKEDITOR.instances[instanceName].updateElement()
        }
    }

    let $this = jQuery(this);
    let dataCallback = $this.attr('data-callback');
    let dataArguments = $this.attr('data-arguments');
    let dataHideForm = $this.attr('data-hideform');
    let dataButtonName = $this.attr('data-button');
    let dataButtonClass = $this.attr('data-button-class');
    let dataButtonData = dataButtonName ? 'button=' + dataButtonName : 'button=';
    let errorType = $this.attr('data-error');
    jQuery(event.delegateTarget).hide();

    // if ($this.attr('data-ajaxloader') == 1) {
    //     dtc.startAjaxLoader($this);
    //     $('.ajax-light-loader').css('position', 'fixed')
    // }

    let btn = null;
    if (dataButtonClass) {
        btn = $(this).find(dataButtonClass);
    }
    if (btn) {
        btn.addClass('spinning');
        btn.prop('disabled', true);
    }


    jQuery.ajax({
        type: 'post',
        url: $this.attr('action'),
        data: $this.serialize() + '&' + dataButtonData + '&ajax-request=' + $this.attr('id'),
        success: function (data) {

            //dtc.stopAjaxLoader($this);
            if (data == 'denied') {
                //Session ist abgelaufen, muss sich neu anmelden
                window.location.reload()
            } else {
                let obj = jQuery.parseJSON(data);

                $this.find('div.alert').html('').hide();
                $('.errorSummary').fadeOut(400, function () {
                    $(this).remove()
                });

                $this.find('.is-invalid').removeClass('is-invalid');
                $this.find('.invalid-feedback').remove();

                if (!jQuery.isEmptyObject(obj) && !obj.redirect && !obj.reload && !obj.notify && !obj.empty && !obj.callback && !obj.flash) {

                    let displayErrors = {};
                    if (obj.error) {
                        displayErrors = obj.error
                    } else {
                        displayErrors = obj
                    }

                    let fieldName = null;
                    let $label = null;
                    let attrid = null;
                    let labelText = null;
                    let $yiiField = null;

                    let hasError = false;

                    if (errorType) {
                        jQuery.each(displayErrors, function (index, value) {
                            $yiiField = jQuery('#' + index);
                            if ($yiiField.length > 0) {
                                $yiiField.addClass('is-invalid').after('<div class="invalid-feedback">' + value + '</div>');
                            }
                        });

                        let firstErrorField = $('form:visible').find('.is-invalid').first();
                        let offsetTop = 0;
                        if (firstErrorField.length > 0) {
                            offsetTop = firstErrorField.offset().top - ($('.navigation_bar').height() + 100);
                        }
                        $('html, body').animate({scrollTop: offsetTop}, 'slow');
                    } else {
                        jQuery.each(displayErrors, function (index, value) {

                            let selector = '#' + index + '_em_';
                            // if ($submitBtn.attr('data-uniqid')) {
                            //     selector = '#' + index + '-' + $submitBtn.data('uniqid') + '_em_'
                            // }
                            $yiiField = jQuery(selector);

                            if ($yiiField.length > 0) {
                                $yiiField.html(value).show()
                            }
                        });
                    }
                } else {
                    if (dataCallback) {
                        dtc.callFunctionByName(dataCallback, window, dataArguments);
                        //$submitBtn.button('reset')
                        dtc.stopAjaxLoader($this)
                    } else {
                        //removed caused login error
                        /*if (obj.dataLayerEvent) {
                                dataLayer.push(obj.dataLayerEvent);
                            }*/
                        if (obj.redirect) {
                            window.location.href = obj.redirect
                        } else if (obj.reload) {
                            window.location.reload()
                        } else if (obj.notify) {
                            // dtc.notify(obj.notify, obj.type, $this.parent(), obj.fadeIn, obj.fadeOut);
                            // $.toast({
                            //     title: obj.title,
                            //     content: obj.notify,
                            //     type: obj.type,
                            //     delay: 5000
                            // });
                            showNotify(obj.notify);

                        } else if (obj.flash) {
                            //console.log('flash', obj.flash)
                            obj.type = obj.type ? obj.type : 'info';
                            let $flashElement = jQuery('<div id="form-flash-' + $this.attr('id') + '" class="form-flash alert alert-' + obj.type + '" />');
                            //console.log('flash element: ', $flashElement)
                            $flashElement.html(obj.flash);
                            obj.replace = obj.replace ? obj.replace : false;
                            let flashContainer = obj.el ? $(obj.el) : $this.parent();

                            if (obj.replace) {
                                flashContainer.html($flashElement)
                            } else {
                                if ($('.userProfil .alert-success').length === 0) {
                                    flashContainer.append($flashElement);
                                }
                            }
                            obj.scrolltop = obj.scrolltop ? obj.scrolltop : false;
                            if (obj.scrolltop) {
                                let offsetHeader = $('.sticky-placeholder').length > 0 ? $('.sticky-placeholder').height() : 0;
                                $('html, body').animate({scrollTop: 0 + offsetHeader}, 'slow')
                            }
                        } else if (obj.empty) {
                        } else if (obj.dataCallback) {
                            dtc.callFunctionByName(obj.callback, window, obj.dataArguments);
                            //$submitBtn.button('reset')
                        } else if (obj.nothing) {
                            //$submitBtn.button('reset')
                        } else {
                            window.location.href = window.location.href
                        }

                    }
                }
                if (btn) {
                    btn.removeClass('spinning');
                    btn.prop('disabled', false);
                }
            }
        },
        error: function (xhr, status, error) {
            if (btn) {
                btn.removeClass('spinning');
                btn.prop('disabled', false);
            }
            stopLoaderSpinner();
        }

    });

    /**
     * Accouont login/registration/forgot
     */



    $('.dont-submit').prop('disabled', false);

    return false
})


dtc.ajaxForm();


//loader spinner
function startLoaderSpinner(element) {
    var loaderElement = '<div class="ajaxLoaderElement">\n\
                            <svg class="ajaxLoaderElement_spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">\n\
                                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>\n\
                            </svg>\n\
                        </div>';
    
    element.prepend(loaderElement);
}
window.startLoaderSpinner = startLoaderSpinner;
function stopLoaderSpinner() {
    $('.ajaxLoaderElement').remove();
}

window.stopLoaderSpinner = stopLoaderSpinner;
$( document ).ajaxSuccess(function() {
    stopLoaderSpinner();
});


//no menu transitions on window resize
var timeout,
    offCanvasMenu = $('.offCanvasMenu');

$(window).on('resize', function(){
    var sidebarNavi = $('.sidebarElement.-navigation');

    clearTimeout(timeout);
    sidebarNavi.addClass('preventTransition');
    offCanvasMenu.addClass('-preventTransition');
    timeout = setTimeout(function(){
        sidebarNavi.removeClass('preventTransition');
        offCanvasMenu.removeClass('-preventTransition');
    }, 100);
});

//off canvas menu

//off canvas menu
$(document).on('click', '.offCanvasMenu_open', function () {
    if ($(window).width() <= 991) {
        $(this).closest('.main').find('.offCanvasMenu:first').addClass('-show');
        $('body').addClass('canvasOpen');
    }
});

$(document).on('click', '.offCanvasMenu_close', function () {
    $(this).closest('.offCanvasMenu').removeClass('-show');
    $("body").removeClass('canvasOpen');
});


//  Reset mobile classes when above tablet width
var $window = $(window);
function removeMobileClasses() {
    var windowsize = $window.width();
    if (windowsize > 991) {
        $('body').removeClass('canvasOpen');
        offCanvasMenu.removeClass('-show');
    }
}
removeMobileClasses();
$(window).resize(removeMobileClasses);

//UI dropdown checklist boxes

var checkboxItem = '<span class="checkbox"></span>';

var existCondition = setInterval(function() {
    if ($('.ui-dropdownchecklist-item').length) {
        $('.ui-dropdownchecklist-item').each(function () {
            $(this).find('input:checkbox').after(checkboxItem);
        });
       clearInterval(existCondition);
    }
}, 100); // check every 100ms


function initFullTextSearchAutoComplete(inputId, lang, containerel) {
    var srsites_ = new Array();
    var srproducts_ = new Array();

    var elInput = $('#' + inputId);

    const searchresultscontainer = createApp({
        setup() {
            const state = reactive({
                sites: srsites_,
                products: srproducts_,
                suggestions: [],
                stats: [],
                keys: [],
                lang: lang,
                searching: false
            });

            const imgLoadingError = (event) => {
                event.target.src = "/resources/public/dtc/media/search-img-placeholder.svg";
            };

            const searchQuery = computed(() => {
                return state.suggestions.map(value => {
                    value = value.replace(/\s/g, "+");
                    return '/search?q=' + value;
                });
            });

            const generatedSites = computed(() => {
                let sites = state.sites;
                let newArr = [];
                if (sites[0].length < 6) {
                    newArr = JSON.parse(JSON.stringify(sites));
                    newArr.splice(1, 1);
                } else {
                    sites.pop();
                    newArr = sites;
                }
                return newArr;
            });

            const modifiedContent = computed(() => {
                if (generatedSites.value.length == 2 && state.sites[0].length < 6) {
                    let totalItems = 6;
                    let sites = state.sites;
                    let freePlaces = totalItems - sites[0].length;
                    let newItems = sites[1].slice(0, freePlaces);

                    return {
                        'items': newItems,
                        'key': state.keys[1]
                    };
                }
                return false;
            });

            watch(() => state.sites, () => {
                let body = document.querySelector('body');
                let searchClose = document.querySelector('.searchResults_close');
                let searchField = document.querySelector('#searchfield');

                if (searchField.value != '') {
                    searchClose.classList.add('-show');
                    body.classList.add('-fixed');
                    state.searching = true;
                } else {
                    searchClose.classList.remove('-show');
                    body.classList.remove('-fixed');
                    state.searching = false;
                }
            });

            return {
                state,
                imgLoadingError,
                searchQuery,
                generatedSites,
                modifiedContent,
                Yii
            };
        },
        template: `
    <div v-show="state.searching" class="searchResults">
        <div v-if="state.sites.length > 0 || state.products.length > 0" class="searchResults_wrapper">
            <div class="searchResults_secondary">
                <div class="suggestions">
                    <h5>{{ Yii.t('frontend', 'Vorschläge') }}</h5>
                    <a v-for="(suggestion, index) in state.suggestions" :href="'/' + state.lang + searchQuery[index]" :title="suggestion"> {{ suggestion }}</a>
                </div>
                <ul class="categorys">
                    <li><h5>{{ Yii.t('frontend', 'Kategorien') }}</h5></li>
                    <li v-for="(stat, index) in state.stats">
                        <a v-if="index != 'unfiltered'" :href="'/' + state.lang + stat.link">{{ stat.title }} <span>{{ stat.total }}</span></a>
                    </li>
                </ul>
            </div>
            <div v-if="state.sites.length > 0" :class="[state.products.length == 0 ? 'searchResults_primary class1' : 'searchResults_primary class2']">
                <div v-for="(site, index) in generatedSites">
                    <h5 v-if="site[0].label == 'Blog'">Magazin</h5>
                    <h5 v-else>{{site[0].label}}</h5>
                    <a v-for="itm in site" :href="(itm.path.indexOf('/' + state.lang + '/') !== 0 ? '/' + state.lang + itm.path : itm.path)" class="searchResults_item">
                        <div class="imgWrapper">
                            <img :src="itm.picUrl" @error="imgLoadingError" class="image">
                        </div>
                        <p>{{ itm.title }}</p>
                    </a>
                    <a :href="(state.stats[state.keys[index]].link.indexOf('/' + state.lang + '/') !== 0 ? '/' + state.lang + state.stats[state.keys[index]].link : state.stats[state.keys[index]].link)" class="showAllResults_link">{{ Yii.t('frontend', 'Alle Ergebnisse anzeigen') }}<span>{{state.stats[state.keys[index]].total}} {{ Yii.t('frontend', 'Treffer') }}</span></a>
                    <div class="searchResults_modified" v-if="index == 0 && modifiedContent">
                        <h5>{{state.sites[1][0].label}}</h5>
                        <a v-for="item in modifiedContent.items" :href="(item.path.indexOf('/' + state.lang + '/') !== 0 ? '/' + state.lang + item.path : item.path)" class="searchResults_item">
                            <div class="imgWrapper">
                                <img :src="item.picUrl" @error="imgLoadingError" class="image">
                            </div>
                            <p>{{ item.title }}</p>
                        </a>
                        <a :href="(state.stats[modifiedContent.key].link.indexOf('/' + state.lang + '/') !== 0 ? '/' + state.lang + state.stats[modifiedContent.key].link : state.stats[modifiedContent.key].link)" class="showAllResults_link">{{ Yii.t('frontend', 'Alle Ergebnisse anzeigen') }}<span>{{state.stats[modifiedContent.key].total}} {{ Yii.t('frontend', 'Treffer') }}</span></a>
                    </div>
                </div>
            </div>
            <div v-if="state.products.length > 0" :class="[state.sites.length == 0 ? 'searchResultsProducts class1' : 'searchResultsProducts class2']">
                <h5>{{state.products[0].label}}</h5>
                <a v-for="product in state.products" :href="'/' + state.lang + product.path" class="searchResultItm">
                    <div class="searchImg" v-bind:style="{backgroundImage: 'url(' + product.picUrl + ')'}"></div>
                    <div class="searchItmRight">
                        <div class="searchProductTitle">{{product.title}}</div>
                        <div class="searchProductPrice hidden">{{product.price}}</div>
                    </div>
                </a>
            </div>
        </div>
        <div class="searchResults_loader" v-if="state.sites.length == 0">
            <svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="spinner_path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
        </div>
        <p class="fail">{{ Yii.t('frontend', 'Keine Suchresultate gefunden') }}</p>
    </div>
    `
    }).mount(containerel);

    $(function (){
        $('.account--switch-card').on('click', function (e) {
            e.preventDefault();
    
            let target = $(this).data('target');
            $('.userLogin .formBlock:not(.-hidden)').addClass('-hidden');
            $('.userLogin ' + target).removeClass('-hidden');
    
            $('.formBlock:not(.-hidden) form .form-control:first').focus();
        });

        function clearSearch() {
            searchresultscontainer.state.searching = false;
            $('body').removeClass('-fixed');
            $('#searchfield').val('');
            $('.searchResults_close').removeClass('-show');
        }
    
        $('.searchResults_close').click(function() {
           clearSearch();
        })

        $(document).on('click', function (e) {
            if (($(e.target).closest(".searchResults").length === 0) && e.target.id != 'searchfield') {
                clearSearch();  
            }
        });
    
        var navibar = $('.navigation_bar');
    
        $('#searchfield').focusin(  
            function(){  
                navibar.addClass('-searching');
            }).focusout(  
            function(){
                navibar.removeClass('-searching');
        });
    
    })

    var sites = new Array();
    var products = new Array();
    var counter = 0;
    var lastSync = 0;
    var suggestions = [];
    var stats = [];
    var fail = $('.searchResults_loader .fail');
    var searchResults = $('.searchResults');

    var typingTimer;
    var doneTypingInterval = 200;

    $('#' + inputId).on("input", function ()
    {
        clearTimeout(typingTimer);
        
        if($('#' + inputId).val())
        {
            typingTimer = setTimeout(doSearch, doneTypingInterval);
        }
    });

    let xhr;
    function doSearch() {
        var myCounter;
        counter++;
        myCounter = counter;
        searchresultscontainer.state.sites = [];
        searchresultscontainer.state.products = [];

        if (elInput.val().length > 1) {
            if (typeof xhr !== "undefined") {
                xhr.abort();
            }
            xhr = $.ajax({
                url: '/' + lang + '/cms/search/search',
                dataType: 'json',
                data: { q: $('#' + inputId).val(), type: $('#' + inputId).data('type'), f: 'json' },
                success: function success(data) {

                    var items;
                    sites = new Array();
                    products = new Array();
                    stats = data.stats;
                    searchresultscontainer.state.keys = data.link_keys;

                    if(data.items.length == 0) {
                        searchResults.addClass('-noResults')
                    }
                    else {
                        searchResults.removeClass('-noResults')
                    }

                    $('.-imgLoadingError').removeClass('-imgLoadingError');
                    
                    $(data.items).each(function (index, value) {
                        if (value.sku != null) {
                            products.push(value);
                        } else {
                            if (sites[value.label] == undefined) {
                                sites[value.label] = new Array();
                            }
                            sites[value.label].push(value);
                        }
                    });

                    function suggestionList() {
                        $(data.suggestions).each(function () {
                            suggestions.push(this);
                        });

                        suggestions = suggestions.join();
                        suggestions = suggestions.split(',');
                        suggestions = suggestions.slice(1).slice(-5);

                        function onlyUnique(value, index, self) {
                            return self.indexOf(value) === index;
                        }
                        suggestions = suggestions.filter(onlyUnique);

                        searchresultscontainer.state.suggestions = suggestions;
                    } 

                    suggestionList();

                    var temparr = new Array();
                    Object.keys(sites).forEach(function (name, index) {
                        temparr.push(sites[name]);
                    });

                    if (myCounter == counter) {
                        lastSync = counter;
                        searchresultscontainer.state.sites = temparr;
                        searchresultscontainer.state.products = products;
                        searchresultscontainer.state.stats = stats;

                    } else {
                        
                        if (lastSync != counter) {
                        
                            searchresultscontainer.state.sites = temparr;
                            searchresultscontainer.state.products = products;
                            searchresultscontainer.state.stats = stats;
                        }
                    }
                    console.log("data", data);

                    if(data.items.length == 0) {
                        searchresultscontainer.state.sites = temparr;
                    }
                }
            });
        }
    }
}
window.initFullTextSearchAutoComplete = initFullTextSearchAutoComplete;
